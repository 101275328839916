<template>
  <div class="carousel-main">
    <el-carousel
      direction="vertical"
      trigger="click"
      :autoplay="true"
      :interval="carTime"
      indicator-position="none"
      class="carouselBox"
      ref="lunbo"
      @change="carouselChange"
    >
      <el-carousel-item v-for="(item,index) in datas" :key="item.id">
        <video
          ref="videoPlayer"
          v-if="item.storyType == 'VIDEO'"
          type="video/mp4"
          controls
          :poster="item.thumbnailInfo.url"
          :src="item.mapProperties.accessUrl"
          autoplay
          loop
          muted
          style="cursor: pointer; width: 100%"
          @click="toDetail(item)"
        ></video>
        <img v-if="index===1" :src="day === 0 ? item.thumbnailInfo.url : require('../components/img/'+day+'.jpg')" alt="">
        <img
          :src="item.thumbnailInfo.url"
          @click="toDetail(item)"
          style="cursor: pointer"
        />
        <!-- <div v-if="index === 0" class="countdown">{{ days }} 天</div> -->
        <!-- <Countdown v-if="index===1" class="countdown" :target-date="endDate" @getDays="getDays"/> -->
      </el-carousel-item>
    </el-carousel>
    <ul class="context_ul">
      <li
        v-for="(item, index) in datas"
        :key="index"
        :class="activeIndex === index ? 'active' : ''"
        @click="setIndex(index)"
      >
<!--        <span v-if="index < 10" style="font-size: 1.04167vw;position: relative;left: 0.36458vw;">。</span>-->
<!--        <span v-else>{{ index }}</span>-->
        <span class="circle"></span>
      </li>
    </ul>
  </div>
</template>
<script>
  //import Countdown from '@/components/Countdown'
export default {
  name: "WgCarousel",
  components:{
   // Countdown
  },
  props: {
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      carTime: 6000,
      activeIndex: 0,
      datas: [],
      day:0,
      endDate: '2024-10-19T24:00:00' // 目标日期
    };
  },
  mounted() {
  },
  beforeDestroy(){
  },
  methods: {
    getDays(day){
      this.day = day;
    },
  
    carouselChange(cerIndex) {
      if (this.datas[cerIndex].videoTime) {
        this.carTime = Number(this.datas[cerIndex].videoTime) * 1000;
      } else {
        this.carTime = 6000;
      }
      this.activeIndex = cerIndex;
      //   console.log(this.carTime);
    },
    toDetail(item) {
      this.$emit("toDetail", item);
    },
    setIndex(index) {
      this.$refs.lunbo.setActiveItem(index);
    },
  },
  watch: {
    columnIds: {
      async handler(val) {
        this.activeIndex = 0;
        if (val) {
          const params = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);
          console.log('resresresresres', res, params)
          let list = [];
          res.data.content.forEach(async (item) => {
            if (item.thumbnailInfo) {
              if (item.thumbnailInfo.url) {
                item.imageUrl = item.thumbnailInfo.url;
                list.push(item);
              }
            }
          });
          this.datas = list;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel-main {
  position: relative;
  .context_ul {
    font-weight: 400;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
    line-height: 52px;
    list-style: none;
    float: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: absolute;
    bottom: 49px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 999;
    cursor: pointer;
    .circle {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: #eee;
      border-radius: 8px;
    }
  }
  .context_ul li {
    float: left;
    margin-left: 70px;
    font-weight: 400;
    font-size: 20px;

    line-height: 52px;
    padding: 0 5px;
    &.active {
      //color: #fff;
      //border-bottom: 1px solid #fff;
      .circle {
        background-color: red;
      }
    }
    // &:hover{
    //   color: #fff;
    //   border-bottom: 1px solid #fff;
    // }
  }
  .context_ul li:first-child {
    margin-left: 0;
  }
  .carouselBox {
    position: relative;
    height: 56.25vw;
    ::v-deep .el-carousel__container {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .countdown{
    z-index: 99999;
    position: absolute;
    top: 50%;
    transform: translate(-50%);
    color: #000;
    right: 0;

    font-size: 100px;
    display: inline-block;
  }
}
</style>
